<template>
  <!-- couldManage -->
  <div>
     <myheader></myheader>
      <div class="img-header">
        <img src="../../../src/assets/backImg.png"/>
      </div>
    <!-- Css用-，方法，变量用小驼峰， -->
    <div class="out">
      <div class="plot-warp-up">
        <div class="plot">平台简讯</div>
        <div class="line-blue"></div>
        <div class="pc">
          <div class="pc-img"><img src="@/assets/couldManage/pc.jpg" alt="" /></div>
          <div class="pc-content1">
            云管平台提供公有云、私有云、混合云统一调配管理、云资源的成本分析一站式管理解决方案。公有云支持厂商包括华为云、腾讯云、阿里云、百度云及亚马逊。 
            公有云资源组件具体管理包括公有云资源访问配置、云主机生命周期管理、云主机状态管理、云主机批量操作、公有云镜像管理等功能。私有云组件主要是对OpenStack和Kubernetes进行的管理。对Kubernetes提供集群管理、命名空间、Kubernetes实例的管理功能；对OpenStack提供集群管理、实例管理、网络管理、镜像管理的管理功能。
          </div>
        </div>
      </div>
    </div>

    <div class="two" hidden>
      <div class="could-zujian">
        <div class="could-zujian-title">核心功能</div>
        <div class="line-blue"></div>

        <div class="could-zujian-content">
          目前公有云组件支持接入的公有云厂商包括华为云、腾讯云、阿里云、百度云及亚马逊。公有云资源组件具体管理包括公有云资
          源访问配置、云主机生命周期管理、云主机状态管理、云主机批量操作以及公有云镜像管理等功能。
        </div>
        <div class="could-zujian-img"><img src="@/assets/couldManage/could.jpg" alt="" /></div>
      </div>
    </div>
    <div class="out" hidden>
      <div class="plot-warp-up">
        <div class="plot">云管平台私有云组件</div>
        <div class="line-blue"></div>
        <div class="pc">
          <div class="pc-img"><img src="@/assets/couldManage/openStack.jpg" alt="" /></div>
          <div class="pc-content">
            私有云组件主要是对Openstack和Kubernetes进行 管理。对于K8s，提供集群管理、命名空间、K8S实 例的管理功能;对于Openstack，提供集群管理、实 例管理、网络管理、镜像管理的管理功能。
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="out" hidden>
        <div class="plot-warp-up">
          <div class="plot">服务器组管理</div>
          <div class="line-blue"></div>
          <div class="pc">
            <div class="pc-content">
              服务器组管理主要是针对服务器组分类，创建服务器 组的时候可以使用不同的类型加以区分，编辑服务器 信息、并跳转到iumpserver进行字符界面控制。提供用户、用户组进行单独或者批量的授权，可以按节
              点、按资产进行授权。
            </div>
            <div class="pc-img"><img src="@/assets/couldManage/fuwuq.jpg" alt="" /></div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="out" hidden>
        <div class="plot-warp-up">
          <div class="plot">公有云生命周期管理</div>
          <div class="line-blue"></div>
          <div class="pc">
            <div class="pc-img"><img src="@/assets/couldManage/couldPublic.jpg" alt="" /></div>
            <div class="pc-content">生命周期管理是指基于不同公有云厂商提供的云主机 控制API对该厂商配置账户下的云主机全生命周期管 理。主要包括弹性云服务器的创建、删除、修改、查询等。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ji-qun">
      <div>
        <div class="plot">核心功能</div>
        <div class="line-blue"></div>
        <img class="ji-qun-img" src="@/assets/jicheng.jpg" alt="" />
        <div class="two-piece">
          <div class="piece">
            <div class="jiqun-title">多云纳管 混合式管理</div>
            <div class="jiqun-content">针对公有云、私有云、容器云异构混合资源，提供一站式管理</div>
          </div>
          <div class="piece2">
            <div class="jiqun-title">云资源全生命周期管理</div>
            <div class="jiqun-content">覆盖Day0到DayN的全生命周期，按需获取多云环境资源的创建、扩容和回收</div>
          </div>
        </div>
        <div class="center">
          <div class="piece">
            <div class="jiqun-title">计费管理</div>
            <div class="jiqun-content">提供私有云单价计费功能，自动统计实例费用，方便计算成本</div>
          </div>
        
        </div>
      </div>
    </div>
     <mybottom></mybottom> 
  </div>
</template>

<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';
export default {

    components:{myheader,mybottom},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.img-header {
    margin: 0 auto;
    width: 100%;
    min-width: 1200px;
    max-width: 1920px;
    /* height: 420px; */
    /* background: url('../../../src/assets/backImg.png'); */
    /* background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size:contain; */
    /* background-origin: 50%; */
    /* background-size: 100%; */
    img{
      width: 100%;
    }
}
.out {
  margin: 0 auto;
  width: 1200px;
  /* text-align: center; */
  /* display: flex;
    justify-content: center; */
}
.plot {
  font-size: 30px;
  font-weight: 800px;
  display: flex;
  justify-content: center;
  padding-top: 70px;
  color: black;
}
.line-blue {
  background-color: #2e71fe;
  width: 60px;
  height: 3px;
  border-radius: 5px;
  margin-top: 25px;
  margin-left:auto ;
  margin-right:auto ;
}

.plot-warp-up {
  height: 552px;
  width: 1200px;
}
.pc {
  display: flex;
  margin-top: 80px;
}
.pc-img {
  margin-left: 80px;
}
.pc-content {
  font-size: 18px;
  width: 600px;
  margin-top: 35px;
  margin-left: 100px;
  display: flex;
  justify-content: center;
  color: #000;
  line-height: 35px;
}
.pc-content1 {
  font-size: 18px;
  width: 600px;
  margin-left: 100px;
  display: flex;
  justify-content: center;
  color: #000;
  text-indent: 2em;
  line-height: 35px;
}
.server {
  width: 1200px;
  height: 550px;
  /* background-color: #f9f9f9; */
  text-align: center;
  margin: 0px auto;
}
.two {
  /* text-align: center; */
  width: 100%;
  min-width: 1200px;
  background-color: #f9f9f9;
  height: 650px;
  display: flex;
  justify-content: center;
}
.could-zujian {
  width: 1200px;
  margin: 0 auto;
}
.could-zujian-title {
  font-size: 30px;
  color: black;
  /* margin: 0 auto; */
  display: flex;
  justify-content: center;
  margin-top: 70px;
}
.could-zujian-content {
  width: 900px;
  font-size: 16px;
  margin: 0 auto;
  margin-top: 35px;
  color: black;
  line-height: 30px;
}
.could-zujian-img {
  margin-top: 45px;
  display: flex;
  justify-content: center;
}
.ji-qun {
  width: 100%;
  min-width: 1200px;
  height: 890px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  position: relative;
}
.two-piece {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.center{
  display: flex;
  justify-content: center;
  margin-top: 150px;
}
.piece {
  width: 423px;
  height: 180px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgb(220, 219, 219);
}
.piece2 {
  width: 423px;
  height: 180px;
  background-color: #fff;
  margin-left: 350px;
  box-shadow: 0px 0px 20px rgb(220, 219, 219);
}
.jiqun-title {
  color: #2e71fe;
  font-size: 18px;
  margin-top: 30px;
  font-weight: 500;
  margin-left: 20px;
}
.jiqun-content {
  font-size: 16px;
  color: #000;
  margin-left: 20px;
  margin-top: 10px;
  width: 370px;
  line-height: 30px;
}
.ji-qun-img {
  position: absolute;
  margin-left: 480px;
  margin-top: 100px;
}
</style>

